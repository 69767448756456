import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container } from './box.css';

const Box = ({ children, style, ...props }) => (
  <Wrapper style={style}>
    <Container {...props}>{children}</Container>
  </Wrapper>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

Box.defaultProps = {
  style: null,
};

export default Box;
