import React from 'react';
import PropTypes from 'prop-types';

import { Styled } from './text.css';

const Text = ({ children, ...rest }) => <Styled {...rest}>{children}</Styled>;

Text.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Text;
