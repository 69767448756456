import styled from 'styled-components';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      text-transform: uppercase;
      font-size: ${props => props.theme.font.size.small};

      & + li {
        margin-left: 2rem;
      }
    }

    .active {
      color: ${props => props.theme.colors.secondary};
      cursor: default;
    }
  }
`;
