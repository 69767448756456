import React, { Fragment } from 'react';
import Head from 'components/head';
import GlobalStyle from 'global.css.js';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme';
import Content from './content';

const Layout = props => (
  <div>
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <Head />
        <Content {...props} />
      </Fragment>
    </ThemeProvider>
  </div>
);

export default Layout;
