import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Navbar from 'components/navbar';
import Footer from 'components/footer';
import withSize from 'hoc/withSize';
import { Container } from './content.css';

function Content({ data, children, noFooterMargin, footnotes }) {
  return (
    <Fragment>
      <Navbar title={data.site.siteMetadata.siteTitle} logo={data.file} />
      <Container>{children}</Container>
      <Footer noMargin={noFooterMargin} footnotes={footnotes} />
    </Fragment>
  );
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  noFooterMargin: PropTypes.bool,
  footnotes: PropTypes.array,
};

Content.defaultProps = {
  noFooterMargin: false,
  footnotes: null,
};

const ContentWithQuery = props => (
  <StaticQuery
    query={graphql`
      query ContentQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
        file: file(relativePath: { eq: "images/logo.png" }) {
          childImageSharp {
            fixed(height: 70, quality: 90) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => <Content data={data} {...props} />}
  />
);

ContentWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSize(ContentWithQuery);
