import styled from 'styled-components';
import { navHeight } from '../../constants/theme';
import MEDIA from 'helpers/mediaTemplates';

const Wrapper = styled.header`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 100;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 1);
`;

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${navHeight}px;

  h2 {
    color: #757575;
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;

export default { Wrapper, Container };
