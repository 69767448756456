import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  margin-top: ${props => (props.noMargin ? 0 : props.theme.spacing.section)};
  padding: 50px 0;
  background-color: #383c47;
  border-top: 4px solid ${props => props.theme.colors.secondary};
  ${MEDIA.MAX_MEDIUM`
    margin-top: calc(${props =>
      props.noMargin ? 0 : props.theme.spacing.section} / 2);
  `};
`;

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    color: #9ba1b0;
    font-size: ${props => props.theme.font.size.small};
  }
  ${MEDIA.MAX_MEDIUM`
    flex-direction: column;
    align-items: center;
    text-align: center;
  `};
`;

export const ContactInfo = styled.div`
  .header {
    margin-bottom: calc(${props => props.theme.spacing.padding} / 2);
    font-weight: bold;
  }
  a {
    color: ${props => props.theme.colors.secondaryLight1};
  }
  ${MEDIA.MAX_MEDIUM`
    margin-bottom: calc(${props => props.theme.spacing.padding} * 2);
  `};
`;
