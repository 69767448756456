import styled from 'styled-components';
import { navHeight } from 'constants/theme';

export const Container = styled.div`
  text-align: center;
  position: relative;
  .dropdown {
    transition-duration: 0.3s;
    position: fixed;
    top: ${navHeight}px;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: #565c6c;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    opacity: 0.95;
  }
  li {
    padding-bottom: ${props => props.theme.spacing.padding};
  }
  li:first-child {
    padding-top: calc(${props => props.theme.spacing.padding} * 2);
  }
  li:last-child {
    padding-bottom: calc(${props => props.theme.spacing.padding} * 2);
  }
  a {
    color: #fff;
    font-weight: bold;
    transition: color 0.2s ease;
    text-decoration: none;
    font-size: ${props => props.theme.font.size.large};
    text-transform: uppercase;

    &:hover {
      color: ${props => props.theme.colors.secondaryLight1};
    }
  }
`;
