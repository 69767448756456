import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/box';
import Text from 'components/text';

import { Wrapper } from './footnotes.css';

const Footnotes = ({ elements, noMargin }) => (
  <Wrapper noMargin={noMargin}>
    <Box>
      {elements &&
        elements.map((el, i) => (
          <Text key={i} small mb={i === elements.length - 1 ? '0px' : '5px'}>
            {`${i + 1}. ${el}`}
          </Text>
        ))}
    </Box>
  </Wrapper>
);

Footnotes.propTypes = {
  elements: PropTypes.array.isRequired,
  noMargin: PropTypes.bool.isRequired,
};

export default Footnotes;
