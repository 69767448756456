import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/box';
import Footnotes from 'components/footnotes';

import { Wrapper, Container, ContactInfo } from './footer.css';
import { StaticQuery, graphql } from 'gatsby';

function Footer({ data, noMargin, footnotes }) {
  const { mail } = data.site.siteMetadata.siteAddress;
  return (
    <>
      {footnotes && <Footnotes elements={footnotes} noMargin={noMargin} />}
      <Wrapper noMargin={noMargin || !!footnotes}>
        <Box>
          <Container>
            <ContactInfo>
              <p className="header">Contact us</p>
              <p>
                <a href={`mailto:${mail}`}>{mail}</a>
              </p>
            </ContactInfo>
            <p>{data.site.siteMetadata.siteCopyright}</p>
          </Container>
        </Box>
      </Wrapper>
    </>
  );
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  noMargin: PropTypes.bool.isRequired,
  footnotes: PropTypes.array,
};

Footer.defaultProps = {
  footnotes: null,
};

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            siteCopyright
            siteAddress {
              mail
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

export default FooterWithQuery;
