import React, { useEffect, useState } from 'react';

function withSize(Component) {
  return function WrappedWithSize(props) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const updateWindowDimensions = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    useEffect(() => {
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);
    }, []);

    return <Component width={width} height={height} {...props} />;
  };
}

export default withSize;
