/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import Icon from 'images/navIcon.svg';
import IconActive from 'images/navIconActive.svg';

const LINKS = [
  { path: '/services', label: 'Services' },
  { path: '/resort-financing-solutions', label: 'Resort Financing Solutions' },
  { path: '/references', label: 'References' },
  { path: '/events', label: 'Events' },
  { path: '/news', label: 'News' },
  { path: '/publications', label: 'Publications' },
  { path: '/contact', label: 'Contact' },
];

export const Links = () => (
  <ul>
    {LINKS.map(l => (
      <li key={l.label}>
        <Link to={l.path} activeClassName="active">
          {l.label}
        </Link>
      </li>
    ))}
  </ul>
);

const Nav = ({ showDropdownIcon, toggleDropdown, dropdownOpen }) => (
  <Container>
    {!showDropdownIcon && <Links />}
    {showDropdownIcon && (
      <div onClick={toggleDropdown}>
        {dropdownOpen ? <IconActive /> : <Icon />}
      </div>
    )}
  </Container>
);

Nav.propTypes = {
  showDropdownIcon: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
};

export default Nav;
