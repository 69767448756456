export default {
  colors: {
    primary: '#002654',
    primaryLight1: '#0047b6',
    secondary: '#FFBF00',
    secondaryLight1: '#ffd966',
    secondaryLight2: '#ffeaaa',
    secondaryLight3: '#feb',
    secondaryLight4: '#fff6dd',
    text: '#4A4A4A',
    grey: '#EDEDED',
  },
  font: {
    family: 'Roboto, Helvetica, sans-serif',
    size: {
      smallest: '10px',
      smaller: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      larger: '22px',
    },
    weight: {
      header: 'bold',
      body: 'regular',
    },
  },
  spacing: {
    section: '60px',
    padding: '15px',
  },
  layout: {
    header: '80px',
    footer: '80px',
    wrapper: '800px',
    button: {
      width: '128px',
      height: '32px',
    },
  },
};
