import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './dropdown.css';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { Links } from '../nav';

const Dropdown = ({ open }) => (
  <Container>
    <SlideDown className={'dropdown'}>{open && <Links />}</SlideDown>
  </Container>
);

Dropdown.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Dropdown;
