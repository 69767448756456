import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  padding-right: ${props => props.theme.spacing.padding};
  padding-left: ${props => props.theme.spacing.padding};
  margin-right: auto;
  margin-left: auto;
  ${MEDIA.MIN_MEDIUM`
    width: 750px;
  `};
  ${MEDIA.MIN_LARGE`
    width: 970px;
  `};
  ${MEDIA.MIN_LARGER`
    width: 1080px;
  `};
  ${MEDIA.MIN_LARGEST`
    width: 1170px;
  `};
`;

export const Container = styled.div`
  text-align: ${props => (props.center ? 'center' : null)};
  padding: ${props =>
    props.spaced ? `${props.theme.spacing.section} 0` : null};

  ${MEDIA.MAX_MEDIUM`
    padding: ${props =>
      props.spaced ? `calc(${props.theme.spacing.section} / 2) 0;` : null};
  `};
`;
