import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import Styled from './navbar.css';
import Nav from './nav';
import Box from 'components/box';
import 'react-slidedown/lib/slidedown.css';
import Dropdown from './dropdown/dropdown';
import withSize from 'hoc/withSize';
import BREAKPOINTS from 'constants/breakpoints';
import Img from 'gatsby-image';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

function Navbar({ title, width, logo }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const showDropdownIcon = width <= BREAKPOINTS['MEDIUM'];

  return (
    <Fragment>
      <Styled.Wrapper>
        <AnimatedContainer>
          <Box>
            <Styled.Container>
              <Link to="/" className="logo-link">
                <Img
                  fixed={logo ? logo.childImageSharp.fixed : {}}
                  alt={title}
                />
              </Link>
              <Nav
                showDropdownIcon={showDropdownIcon}
                toggleDropdown={() => setDropdownOpen(!dropdownOpen)}
                dropdownOpen={dropdownOpen}
              />
            </Styled.Container>
          </Box>
        </AnimatedContainer>
      </Styled.Wrapper>
      <Dropdown open={dropdownOpen} />
    </Fragment>
  );
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  logo: PropTypes.object.isRequired,
};

export default withSize(Navbar);
