import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  margin-top: ${props => (props.noMargin ? 0 : props.theme.spacing.section)};
  padding: 30px 0;
  border-top: 1px solid #ddd;
  background-color: white;
  ${MEDIA.MAX_MEDIUM`
    margin-top: calc(${props =>
      props.noMargin ? 0 : props.theme.spacing.section} / 2);
  `};
`;
